import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, map, tap } from 'rxjs/operators';
import { NgrxService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AllAssignmentsService {
	serviceName = 'assignments';

	pageLimitAPI = 10;

	constructor(private http: HttpClient, private ngrxService: NgrxService) {}

	loadOtherAssignmentsInit(cohortId: number) {
		this.ngrxService.init(this.serviceName, cohortId);
	}

	loadOtherAssignmentsData(cohortId: number) {
		return this.ngrxService.loadData(this.serviceName, cohortId);
	}

	loadOtherAssignmentsFilters(cohortId: number): any {
		return this.ngrxService.loadFilter(this.serviceName, cohortId);
	}

	loadMoreOtherAssignmentsSetFilter(cohortId: number, filter: any) {
		this.ngrxService.setFilter(this.serviceName, cohortId, filter);
	}

	loadOtherAssignmentsAPI(cohortId: number) {
		return this.ngrxService.loadInitAPI(this.serviceName, cohortId).pipe(
			concatMap(() =>
				this.http
					.post<any>(
						`${environment.url.cohort}/${cohortId}/submissions/allAssignment/limit/${this.pageLimitAPI}/page/0`,
						{},
					)
					.pipe(
						map(data => ({
							data: data.entities,
							pageRecord: data.totalCount,
						})),
					),
			),
			tap(({ data, pageRecord }) =>
				this.ngrxService.loadInitDataPagination(
					this.serviceName,
					cohortId,
					data,
					pageRecord,
				),
			),
		);
	}

	loadMoreOtherAssignmentsAPI(cohortId: number, pageNo = 0) {
		return this.ngrxService.loadMoreInitAPI(this.serviceName, cohortId).pipe(
			concatMap(
				() =>
					this.http.post<any>(
						`${environment.url.cohort}/${cohortId}/submissions/allAssignment/limit/${this.pageLimitAPI}/page/${pageNo}`,
						{},
					),
				// .pipe(
				// 	map(data => ({
				// 		data,
				// 	})),
				// ),
			),

			tap((data: any) => {
				this.ngrxService.loadMoreDataPagination(
					this.serviceName,
					cohortId,
					data.entities,
				);
			}),
		);
	}

	loadOtherAssignmentsAPIWithFilter(cohortId, filters) {
		const assignmentFilter = this.convertFilter(filters);

		return this.ngrxService.loadInitAPI(this.serviceName, cohortId).pipe(
			concatMap(() =>
				this.http
					.post<any>(
						`${environment.url.cohort}/${cohortId}/submissions/allAssignment/limit/${this.pageLimitAPI}/page/0`,
						assignmentFilter,
					)
					.pipe(
						map(data => {
							return {
								data: data.entities,
								pageRecord: data.totalCount,
							};
						}),
					),
			),
			tap(({ data, pageRecord }) =>
				this.ngrxService.loadInitDataPagination(
					this.serviceName,
					cohortId,
					data,
					pageRecord,
				),
			),
		);
	}

	loadMoreOtherAssignmentsAPIWithFilter(cohortId, filters, pageNo = 0) {
		const assignmentFilter = this.convertFilter(filters);
		return this.ngrxService.loadMoreInitAPI(this.serviceName, cohortId).pipe(
			concatMap(() =>
				this.http
					.post<any>(
						`${environment.url.cohort}/${cohortId}/submissions/allAssignment/limit/${this.pageLimitAPI}/page/${pageNo}`,
						assignmentFilter,
					)
					.pipe(
						map(data => ({
							data: data.entities,
						})),
					),
			),
			tap(({ data }) => {
				this.ngrxService.loadMoreDataPagination(
					this.serviceName,
					cohortId,
					data,
				);
			}),
		);
	}

	convertFilter(filters) {
		const assignmentFilter: any = {};

		if (filters.assignment) {
			assignmentFilter.assignmentId = filters.assignment;
		}

		if (filters.group) {
			if (filters.group === 'My group') {
				assignmentFilter.groupFilter = 'own';
			} else if (filters.group === 'Other groups') {
				assignmentFilter.groupFilter = 'other';
			}
		}

		if (filters.student) {
			assignmentFilter.email = filters.student;
		}

		if (filters.groupId) {
			assignmentFilter.groupId = filters.groupId;
		}
		return assignmentFilter;
	}

	resetFilters(cohortId) {
		this.ngrxService.resetFilter(this.serviceName, cohortId);
	}
}
