import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { concatMap, tap } from 'rxjs/operators';
import { NgrxService, RudderStack } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ProfileSearchService {
	serviceName = 'profileSearch';
	randomId = {
		'profileSearch-open-door': 0,
		'profileSearch-all': 0,
		'profileSearch-fellow': 0,
		'profileSearch-mentor': 0,
	};

	constructor(
		private http: HttpClient,
		private store: Store<any>,
		private ngrxService: NgrxService,
		private rudderStack: RudderStack,
	) {}

	loadSearchProfileApi(filter: any = {}) {
		this.randomId[this.serviceName] = String(Math.random());

		let payload: any = {
			id: this.randomId[this.serviceName],
			name: 'Search profiles v2',
			profileId: this.rudderStack.currentProfileId,
			searchQuery: {
				...filter,
			},
			searchOpt: {},
			boolQuery: {},
		};

		if ('search' in payload.searchQuery) {
			payload.saveSearch = {
				profileId: this.rudderStack.currentProfileId,
				searchText: payload.searchQuery.search[0],
			};
		}

		if ('sortBy' in payload.searchQuery) {
			delete payload.searchQuery.sortBy;
			payload.sortBy = filter.sortBy;
		}

		if ('profileId' in payload.searchQuery) {
			delete payload.searchQuery.profileId;
			payload.profileId = filter.profileId;
		}

		if ('courses.cohortId AND' in payload.searchQuery) {
			if ('courses.cohortId' in payload.searchQuery) {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.boolQuery['courses.cohortId'] = filter['courses.cohortId'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			} else {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			}
		}

		return this.ngrxService.loadInitAPI(this.serviceName, 'search').pipe(
			concatMap(() =>
				this.http.post<any>(`${environment.url.profile}/search/limit/20`, [
					payload,
				]),
			),
			tap((data: any) => {
				this.updateData(
					[...data[0]?.result].map(re => {
						return {
							...re,
							address: re.address || re.country || re.state || re.city,
							multimedia48: re.multimedia?.replace(
								'terra-do/image/upload',
								'terra-do/image/upload/w_48,h_48,c_limit',
							),
							multimedia240: re.multimedia?.replace(
								'terra-do/image/upload',
								'terra-do/image/upload/w_240,h_240,c_limit',
							),
						};
					}),
					data[0]?.total,
				);
			}),
		);
	}

	updateData(records, total) {
		return this.ngrxService.loadInitDataPagination(
			this.serviceName,
			'search',
			records,
			total,
		);
	}

	loadMoreSearchProfileApi(filter: any = {}, afters: any) {
		let after = afters;
		if (after[0] == -9223372036854776000) {
			// ES can not handle error
			after = ['-9223372036854775808', ...after.slice(1)];
		}

		let payload: any = {
			id: this.randomId[this.serviceName],
			name: 'Search profiles v2',
			profileId: this.rudderStack.currentProfileId,
			searchQuery: { ...filter },
			searchOpt: {},
			boolQuery: {},
			after,
		};

		if ('sortBy' in payload.searchQuery) {
			delete payload.searchQuery.sortBy;
			payload.sortBy = filter.sortBy;
		}

		if ('profileId' in payload.searchQuery) {
			delete payload.searchQuery.profileId;
			payload.profileId = filter.profileId;
		}

		if ('courses.cohortId AND' in payload.searchQuery) {
			if ('courses.cohortId' in payload.searchQuery) {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.boolQuery['courses.cohortId'] = filter['courses.cohortId'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			} else {
				delete payload.searchQuery['courses.cohortId AND'];
				payload.searchQuery['courses.cohortId'] =
					filter['courses.cohortId AND'];
			}
		}

		return this.ngrxService.loadMoreInitAPI(this.serviceName, 'search').pipe(
			concatMap(() =>
				this.http.post<any>(`${environment.url.profile}/search/limit/20`, [
					payload,
				]),
			),
			tap((data: any) => {
				this.ngrxService.loadMoreDataPagination(
					this.serviceName,
					'search',
					[...data[0]?.result].map(re => {
						return {
							...re,
							address: re.address || re.country || re.state || re.city,
							multimedia48: re.multimedia?.replace(
								'terra-do/image/upload',
								'terra-do/image/upload/w_48,h_48,c_limit',
							),
							multimedia240: re.multimedia?.replace(
								'terra-do/image/upload',
								'terra-do/image/upload/w_240,h_240,c_limit',
							),
						};
					}),
				);
			}),
		);
	}

	loadSearchProfileInit() {
		this.ngrxService.init(this.serviceName, 'search');
	}

	loadSearchProfileData() {
		return this.ngrxService.loadData(this.serviceName, 'search');
	}

	loadFilterData() {
		return this.ngrxService.loadFilter(this.serviceName, 'search');
	}

	loadFilterProfileApi(filter: any = {}) {
		this.ngrxService.setFilter(this.serviceName, 'search', filter);
	}

	resetFilterProfileApi() {
		this.ngrxService.resetFilter(this.serviceName, 'search');
	}

	resetState() {
		this.ngrxService.resetState(this.serviceName, 'search');
	}

	deleteProfile(eventId) {
		this.ngrxService.deleteFromState(this.serviceName, 'search', eventId);
	}

	loadSearchExportProfileInit() {
		this.ngrxService.init(this.serviceName, 'export-search');
	}

	loadSearchExportProfileAPI() {
		return this.ngrxService.loadInitAPI(this.serviceName, 'export-search').pipe(
			concatMap(() =>
				this.http.get<any>(
					`${environment.url.profile}/list/testimonial/limit/2/offset/0`,
				),
			),
			tap(data =>
				this.ngrxService.loadInitData(this.serviceName, 'export-search', data),
			),
		);
	}

	loadSearchExportProfileData() {
		return this.ngrxService.loadData(this.serviceName, 'export-search');
	}
}
